<template>
    <v-layout row wrap class="app-employment-type-alias-dialog app-from--hide-details ma-0">
        <v-flex xs12>
            <v-text-field label="Название" box v-model="model.name"/>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: 'app-employment-type-alias-dialog',

        props : {
            model : {}
        },

        data() {
            return {

            }
        }
    }
</script>

<style lang="less">
    .app-employment-type-alias-dialog  {
        max-width: 600px;
    }
</style>